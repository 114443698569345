import styled from 'styled-components';
import {
  BigWheelWheelCircle,
  BigWheelLight1,
	BigWheelLight2
} from 'assets/images';
export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 500px;
  height: 300px;
  margin-top: 30px;
  margin-bottom: -15px;
  z-index: 2;

  .number {
    position: absolute;
    top: 111px;
    right: 118px;
    z-index: 2;
    color: #fff;
    font-size: 27px;
    font-weight: bold;
    font-family: "Carnevalee Freakshow" !important;
    width: 20px;
    text-align: center;
  }
  .number-show {
    animation: numberShow;
  }
  .bigwheel-light {
    background-image: url(${BigWheelLight1});
    background-repeat: no-repeat;
    width: 261px;
    z-index: 1;
    height: 381px;
    background-size: 100%;
    animation: wheelLightBulb 1s infinite;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-top: 6px;
    margin-left: -15px;
  }
  .bigwheel-circle {
    background: url(${BigWheelWheelCircle});
    background-repeat: no-repeat;
    width: 310px;
    z-index: 1;
    height: 316px;
    background-size: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .bigwheel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    top: 7px;

    .wheel {
      position: absolute;
      width: 230px;
      height: 230px;
      transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
      top: 22px;
      margin-left: -11px;
      animation-duration: ${props => props.isTurbo ? '0s' : '2s'};
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;

      img.wheel-img {
        width: 250px;
        height: 250px;
        margin-left: -8px;
        margin-top: -7px;
      }
    }
    .rotate22 {
      animation-name: wheelRotate22;
    }
    .rotate10 {
      animation-name: wheelRotate10;
    }
    .rotate33 {
      animation-name: wheelRotate33;
    }
    .rotate44 {
      animation-name: wheelRotate44;
    }
    .rotate55 {
      animation-name: wheelRotate55;
    }
    .rotate1 {
      animation-name: wheelRotate1;
    }
    .rotate20 {
      animation-name: wheelRotate20;
    }
    .rotate29 {
      animation-name: wheelRotate29;
    }
    .rotate30 {
      animation-name: wheelRotate30;
    }
    .rotate40 {
      animation-name: wheelRotate40;
    }
    .rotate49 {
      animation-name: wheelRotate49;
    }
    .rotate50 {
      animation-name: wheelRotate50;
    }
    .rotateGremlin {
      animation-name: wheelRotateGremlin;
      animation-timing-function: linear;
    }
    .rotateInfinite {
      animation: wheelRotateInfinite 1s linear forwards;
    }
  }

  @keyframes numberShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes wheelLightBulb {
    from {
      background-image: url(${BigWheelLight1});
    }
    to {
      background-image: url(${BigWheelLight2});
    }
  }

  @keyframes wheelRotateGremlin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(2065deg);
    }
  }

  @keyframes wheelRotateInfinite {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(1960deg);
    }
  }

  @keyframes wheelRotate22 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(2375deg);
    }
  }

  @keyframes wheelRotate33 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(2350deg);
    }
  }

  @keyframes wheelRotate44 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(2312deg);
    }
  }

  @keyframes wheelRotate55 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(2117deg);
    }
  }

  @keyframes wheelRotate1 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(2232deg);
    }
  }

  @keyframes wheelRotate29 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(2219deg);
    }
  }

  @keyframes wheelRotate20 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(2220deg);
    }
  }

  @keyframes wheelRotate10 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(2391deg);
    }
  }

  @keyframes wheelRotate30 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(1835deg);
    }
  }

  @keyframes wheelRotate40 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(2160deg);
    }
  }

  @keyframes wheelRotate49 {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(2160deg);
    }
  }

  @keyframes wheelRotate50 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(2265deg);
    }
  }

  canvas {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    &.pitch-intro {
      opacity: 1;
      visibility: hidden;
    }

    &.rolling-red-ball {
      opacity: 1;
      visibility: hidden;
      z-index: 4;
      width: 99px;
      height: 99px;
      top: unset;
      right: unset;
      left: 50%;
      bottom: 0;
      margin-left: -54px;
      margin-bottom: 140px;
    }

  }

  .stopped-red-ball {
    position: absolute;
    z-index: 4;
    width: 99px;
    height: 99px;
    top: unset;
    right: unset;
    left: 50%;
    bottom: 0;
    margin-left: -54px;
    margin-bottom: 125px;
    visibility: hidden;
  }
`;

export const Number = styled.div`
  display: flex;
  font-family: "Varsity Team Bold" !important;
  font-size: 53px;
  color: rgb(36, 46, 66);
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  margin: auto;
  position: absolute;
  bottom: 185px;
  left: 50%;
  z-index: 2;
  width: 100px;
  height: 100px;
  transform: translateX(-50%);
  margin-left: -4px;
  align-items: center;
  justify-content: center;
  text-shadow: -2px 0 #ffffff, 0 2px #ffffff, 2px 0 #ffffff, 0 -2px #ffffff;
  opacity: 0;
  bottom: 196px;
  left: 57.8%;
  font-size: 22px;
`;

export const Gremlin = styled.img`
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  margin-bottom: -8px;
`
export const GremlinConditional = styled(Gremlin)`
  visibility: hidden;
`

export const GremlinJumper = styled.img`
  width: 100px;
  height: 100px;
  position: absolute;
  display: none;
  z-index: 4;
  top: 100%;
  right: 50%;
  margin-right: -52px;
  margin-top: -98px;
  animation: jumpanim1 1s ease-out forwards;
  transform: rotate(90deg);

  @keyframes jumpanim1 {
    0% {
      margin-right: -52px;
    }
    50% {
      margin-right: -550px;
    }
    70% {
      margin-right: -500px;
    }
    100% {
      margin-right: -273px;
      margin-top: -146px;
    }
  }
`

export const GremlinJumper2 = styled(GremlinJumper)`
  animation: jumpanim2 1s ease-out forwards;

  @keyframes jumpanim2 {
    0% {
      margin-right: -52px;
    }
    50% {
      margin-right: -550px;
    }
    70% {
      margin-right: -500px;
    }
    100% {
      margin-right: -268px;
      margin-top: -69px;
    }
  }
`

export const GremlinJumper3 = styled(GremlinJumper)`
  animation: jumpanim3 1s ease-out forwards;

  @keyframes jumpanim3 {
    0% {
      margin-right: -52px;
    }
    50% {
      margin-right: -550px;
    }
    70% {
      margin-right: -500px;
    }
    100% {
      margin-right: -262px;
      margin-top: 7px;
    }
  }
`

export const Smoke = styled.img`
  width: 200px;
  height: 200px;
  position: absolute;
  z-index: 5;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  margin-bottom: -50px;
  visibility: hidden;
`

export const LandingSmoke = styled.img`
  width: 200px;
  height: 200px;
  position: absolute;
  z-index: 5;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  margin-bottom: -8px;
  visibility: hidden;

  &.position1 {
    top: 0;
    margin-top: -199px;
    margin-left: 138px;
  }

  &.position2 {
    top: 0;
    margin-top: -201px;
    margin-left: 212px;
  }

  &.position3 {
    top: 0;
    margin-top: -201px;
    margin-left: 288px;
  }
`